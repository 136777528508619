@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  scrollbar-width: thin;
  scrollbar-color: #1c6ba4;
} */

.card {
  background-color: white;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #1c6ba4;
  border-radius: 14px;
  border: 1px solid #ffffff;
}

ol {
  list-style-type: none;
  counter-reset: item;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  padding-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  padding-bottom: 0.6em;
}

li ol > li:before {
  content: counters(item, ".") " ";
}
